'use strict';
app.factory('dashboardService',
    ['$rootScope', '$http', '$q', '$timeout', '$ocLazyLoad', 'authService', '$filter', 'customLocalStorageService', 'DASHBOARDCONSTANT', 'displayService',
        function ($rootScope, $http, $q, $timeout, $ocLazyLoad, authService, $filter, customLocalStorageService, DASHBOARDCONSTANT, displayService) {
            var urlDashboardAPI = $rootScope["dashboardWebApiurl"];
            var DATA = getInitData();
            const URLConfig = getUrlConfig();
            var fontColorClass = {
                POSITIVE: "positivefontColor",
                NEGATIVE: "negativefontColor",
                NEUTRAL: "neutralfontColor"
            }
            var trendMode = {
                MORE_IS_BETTER: "P",
                MORE_IS_WORSE: "N"
            }

            function getUrlConfig() {
                const getFullUrl = (path) => `${urlDashboardAPI}${path}`;
                return {
                    BacklogPlatformAging: getFullUrl("HarvestingEvacuation/getPlatformBacklogAging"),
                    BacklogInField: getFullUrl("HarvestingCollection/GetInfieldBacklog"),
                    CollectionEvacuationData: getFullUrl("CollectionEvacuation/GetData"),
                    BacklogBinAging: getFullUrl("CollectionEvacuation/GetCropBacklogBinAging"),
                    Collection: getFullUrl("BinCollection/GetData"),
                    BacklogRampAging: getFullUrl("Ramp/GetBacklogAging"),
                    DespatchFromRamp: getFullUrl("Ramp/GetDespatch"),
                    EvacuationFromBin: getFullUrl("EvacuationFromBin/GetData"),
                    EvacuationTimeFromBin: getFullUrl("EvacuationFromBin/GetActivityTime"),
                    EvacuationFromPlatform: getFullUrl("EvacuationFromPlatform/GetData"),
                    EvacuationTimeFromPlatform: getFullUrl("EvacuationFromPlatform/GetActivityTime"),
                    HarvestingEvacuationData: getFullUrl("HarvestingEvacuation/GetData"),
                    Harvesting: getFullUrl("Harvesting/GetPlatformData"),
                    CropHarvestingInField: getFullUrl("Harvesting/GetInFieldData"),
                    HarvestingCollectionData: getFullUrl("HarvestingCollection/GetData"),
                    QCUserLastUpload: getFullUrl("UserTrans/GetLast")
                }
            }


            function getInitData() {
                return {
                    //Backlog
                    BacklogAndBacklogBCCByOUByDateRange: { url: urlDashboardAPI + "Backlog/getRawBacklogAndBacklogBCCByOUByDateRange" },

                    //Manday
                    totalManDayByOUByDateRange: { url: urlDashboardAPI + "qcManday/getTotalMandayByOUByDateRange" },

                    //Weight Variances
                    WeightVarianceByOUByDateRange: { url: urlDashboardAPI + "CropWeight/getWeightVarianceByOUByDateRange" },

                    //Harvesting Inspection (Inspector, Checker)
                    harInspRipenessPie: { url: urlDashboardAPI + "qcHarInspection/GetRipenessInspectionByInspectorByOUByDateRange" },
                    harInspCheckerRipenessPie: { url: urlDashboardAPI + "qcHarInspection/getRipenessInspectionByCheckerByOUByDateRange" },
                    checkerInspRipenessDateTrendsByDateRange: { url: urlDashboardAPI + "qcHarInspection/GetRipenessInspectionVarianceByOUByDateRange" },

                    //GeneralWork Inspection
                    gwInspStatusPie: { url: urlDashboardAPI + "qcGwInspection/GetStatusByOUByDateRange" },

                    attendancePie: { url: urlDashboardAPI + "Attd/GetAttendancePie" },

                    bunchesPie: { url: urlDashboardAPI + "Bunches/GetBunchesPie" },

                    topHarByEstimatedWeight: { url: urlDashboardAPI + "Harvesting/GetTopHarvesters" },

                    GWInspCompletionDateTrendsByDateRange: { url: urlDashboardAPI + "qcGwInspection/GWInspCompletionDateTrendsByDateRange" },
                    top10InspInspector: { url: urlDashboardAPI + "qcGwInspection/Top10GWInspInspector" },
                    latestInspectedInfo: { url: urlDashboardAPI + "qcGwInspection/LatestInspectedInfo" },
                    gwInspTimeline: { url: urlDashboardAPI + "qcGwInspection/GWInspTimeline" },
                    gwInspRatingPieChart: { url: urlDashboardAPI + "qcGwInspection/GWInspRatingPieChart" },
                    gwInspRatingByPercentage: { url: urlDashboardAPI + "qcGwInspection/GetInspectedRatingByPercentage" },
                    gwInspQualityGradedByInspector: { url: urlDashboardAPI + "qcGwInspection/GetQualityGradedByInspector" },
                    gwInspTop20Workers: { url: urlDashboardAPI + "qcGwInspection/GetTop20Workers" },
                    gwInspBottom20Workers: { url: urlDashboardAPI + "qcGwInspection/GetBottom20Workers" },
                };
            }

            function getTrendLabelClass(mode, difference) {
                var classLabel = fontColorClass.NEUTRAL;
                if (!mode || !difference)
                    return classLabel;

                switch (mode) {
                    case trendMode.MORE_IS_BETTER:
                        if (difference > 0)
                            classLabel = fontColorClass.POSITIVE;
                        else if (difference < 0)
                            classLabel = fontColorClass.NEGATIVE;
                        break;
                    case trendMode.MORE_IS_WORSE:
                        if (difference > 0)
                            classLabel = fontColorClass.NEGATIVE;
                        else if (difference < 0)
                            classLabel = fontColorClass.POSITIVE
                        break;
                }
                return classLabel;
            }

            //---------------------------------------------Get function that returns a promise---------------------------------//
            function getData(url, customParam) {
                var dashboardFilter;
                if (customParam &&
                    customParam.dateFrom &&
                    customParam.dateTo) {
                    dashboardFilter = customParam;
                }
                else {
                    dashboardFilter = customLocalStorageService.getQCDashboardFilter();
                    if (dashboardFilter === undefined || !dashboardFilter)
                        dashboardFilter = {};
                    else if (dashboardFilter.TimeModeSelection == DASHBOARDCONSTANT.MODE.BYLATEST.value) {
                        dashboardFilter.dateTo = null;
                        dashboardFilter.dateFrom = null;
                    }
                }

                var config = { params: dashboardFilter };
                return $http.get(url, config);
            }

            function getBacklogByDateRange(customParam) {
                return getData(DATA.backlogByDateRange.url, customParam);
            }

            function getBacklogEstimatedWeightByDateRange() {
                return getData(DATA.backlogEstimatedWeightByDateRange.url);
            }

            function getTotalBacklogBCC(customParam) {
                return getData(DATA.totalBacklogBCCCounts.url, customParam);
            }

            function getRawBacklogAndBacklogBCCByOUByDateRange(customParam) {
                return getData(DATA.BacklogAndBacklogBCCByOUByDateRange.url, customParam);
            }


            //Start Manday region
            function getTotalManDayByDateRange() {
                return getData(DATA.totalManDayByDateRange.url);
            }

            function getTotalManDayByOUByDateRange() {
                return getData(DATA.totalManDayByOUByDateRange.url);
            }

            //End Manday region

            //Start Evacuation region

            function getEvacuationSingleFigureWidget(customParam) {
                return getData(DATA.evacuationSingleFigureWidget.url, customParam);
            }

            //End Evacuation region

            function getHarInspByDateRange(customParam) {
                return getData(DATA.harInspByDateRange.url, customParam);
            }

            //Start Weight Variance region
            function getWeightVarianceByOUByDateRange() {
                return getData(DATA.WeightVarianceByOUByDateRange.url);
            }

            function getHarDespatchMillDateTrendsByDateRange() {
                return getData(DATA.weightVarianceDateTrendsByDateRange.url);
            }

            function getHarDespatchMillMonthTrendsByDateRange() {
                return getData(DATA.weightVarianceMonthTrendsByDateRange.url);
            }
            //End Weight Variance region

            function getCropQualityRipenessByBlockByDateRange() {
                return getData(DATA.cropQualityRipenessByBlockByDateRange.url);
            }

            function getCropQualityUnsatisfactoryByBlockByDateRange() {
                return getData(DATA.cropQualityUnsatisfactoryByBlockByDateRange.url);
            }

            function getLeadTimeByDateRange() {
                return getData(DATA.leadTimeByDateRange.url);
            }

            function getLFWeightPercentByDateRange() {
                return getData(DATA.lfWeightPercentByDateRange.url);
            }

            function getCheckerInspRipenessDateTrendsByDateRange() {
                return getData(DATA.checkerInspRipenessDateTrendsByDateRange.url);
            }

            function getCheckerInspRipenessMonthTrendsByDateRange() {
                return getData(DATA.checkerInspRipenessMonthTrendsByDateRange.url);
            }

            function getInFieldBacklog() {
                return getData(DATA.inFieldBacklog.url);
            }

            function getHarEvaMonthTrendsByDateRange() {
                return getData(DATA.harEvaMonthTrendsByDateRange.url);
            }

            function getHarInspRipenessPie() {
                return getData(DATA.harInspRipenessPie.url);
            }

            function getHarInspRipenessCheckerPie() {
                return getData(DATA.harInspCheckerRipenessPie.url)
            }

            function getGwInspStatusPie() {
                return getData(DATA.gwInspStatusPie)
            }

            function getAttendancePie() {
                return getData(DATA.attendancePie.url);
            }

            function getAttendanceByDateRange() {
                return getData(DATA.attendanceByDateRange.url);
            }

            function getBunchesPie() {
                return getData(DATA.bunchesPie.url);
            }

            function getBunchesByDateRange() {
                return getData(DATA.bunchesByDateRange.url);
            }

            function getLatestTopHarvestersByTotalRipe() {
                return getData(DATA.latestTopHarvestersByTotalRipe.url);
            }

            function getTopHarByEstimatedWeight() {
                return getData(DATA.topHarByEstimatedWeight.url);
            }

            function getHarRnd() {
                return getData(DATA.harRnd.url);
            }

            function getHarInt() {
                return getData(DATA.harInt.url);
            }

            function getFirstDayOfTheMonth() {
                var date = new Date();
                var firstDayOfTheMonth = new Date(date.getFullYear(), date.getMonth(), 1);
                return firstDayOfTheMonth;
            }

            function getFirstDayOfTheYear() {
                var date = new Date();
                var firstDayOfTheYear = new Date(date.getFullYear(), 0, 1);
                return firstDayOfTheYear;
            }

            function getFieldInspecTotalPending(params) {
                var config = {};
                if (params)
                    config.params = params;
                return getData(DATA.fieldInspecTotalPending.url, config);
            }

            function isValidDate(date) {
                if (!angular.isDate(date) && !angular.isDate(new Date(date)))
                    return false;
                //please, i want dont want 0001
                var temp = new Date(date);
                if (temp < 1900)
                    return false;
                return true;
            }

            function getTotalBCCnotInHarvesting(params) {
                var config = {};
                if (params)
                    config.params = params;
                return getData(DATA.totalBCCnotInHarvesting.url, config);
            }

            function getHarEvaBunches(params) {
                var config = {};
                if (params)
                    config.params = params;
                return getData(DATA.harEvaBunches.url, config);
            }

            function getGWInspCompletionDateTrendsByDateRange() {
                return getData(DATA.GWInspCompletionDateTrendsByDateRange.url);
            }

            function getTop10InspInspector() {
                return getData(DATA.top10InspInspector.url);
            }

            function getLatestInspectedInfo() {
                return getData(DATA.latestInspectedInfo.url);
            }

            function getGWInspTimeline() {
                return getData(DATA.gwInspTimeline.url);
            }

            function getGWInspRatingPieChart() {
                return getData(DATA.gwInspRatingPieChart.url);
            }

            var millDashboardData;
            var matureDBData;
            var capexDBData;
            var vehDBData;
            var maBySubCatDBData;
            var rpBySubCatDBData;
            var npBySubCatDBData;
            var rpDBData;
            var npDBData;
            var gcDBData;
            var nurSoldDBData;
            var nurCullingDBData;
            var nurAgingDBData;

            var promises = [];

            $rootScope.$on(DASHBOARDCONSTANT.EVENT.REFRESH_WIDGET, function (event, args) {

                if (displayService.getDisplayInformation().DashboardMenu.findIndex(function (e) {
                    return (e.Attribute.startsWith("lm-mill") && e.Attribute != "lm-mill-mpob-yield") || e.Attribute.startsWith("lm-lab") || e.Attribute.startsWith("lm-crop") || e.Attribute.startsWith("lm-top-low");
                }) > -1) {
                    readMillDashboardData();
                }
        

                if (displayService.getDisplayInformation().DashboardMenu.findIndex(function (e) {
                    return (e.Attribute.startsWith("lm-ma-cost"));
                }) > -1) {
                    readMatureDBData();
                }

                if (displayService.getDisplayInformation().DashboardMenu.findIndex(function (e) {
                    return (e.Attribute.startsWith("lm-rp-cost"));
                }) > -1) {
                    readRPDBData();
                }

                if (displayService.getDisplayInformation().DashboardMenu.findIndex(function (e) {
                    return (e.Attribute.startsWith("lm-np-cost"));
                }) > -1) {
                    readNPDBData();
                }

                if (displayService.getDisplayInformation().DashboardMenu.findIndex(function (e) {
                    return (e.Attribute.startsWith("lm-gc-cost"));
                }) > -1) {
                    readGCDBData();
                }

                if (displayService.getDisplayInformation().DashboardMenu.findIndex(function (e) {
                    return (e.Attribute.startsWith("lm-capex-cost"));
                }) > -1) {
                    readCapexDBData();
                }

                if (displayService.getDisplayInformation().DashboardMenu.findIndex(function (e) {
                    return (e.Attribute.startsWith("lm-veh-cost"));
                }) > -1) {
                    readVehDBData();
                }

                if (displayService.getDisplayInformation().DashboardMenu.findIndex(function (e) {
                    return (e.Attribute.startsWith("lm-nur-sold"));
                }) > -1) {
                    readNurSoldDBData();
                }

                if (displayService.getDisplayInformation().DashboardMenu.findIndex(function (e) {
                    return (e.Attribute.startsWith("lm-nur-culling"));
                }) > -1) {
                    readNurCullingDBData();
                }

                if (displayService.getDisplayInformation().DashboardMenu.findIndex(function (e) {
                    return (e.Attribute.startsWith("lm-nur-aging"));
                }) > -1) {
                    readNurAgingDBData();
                }

                //if (displayService.getDisplayInformation().DashboardMenu.findIndex(function (e) {
                //    return (e.Attribute.startsWith("lm-ma-by-sub-cat-index"));
                //}) > -1) {
                //    readMaBySubCatIndexDBData();
                //}

                //if (displayService.getDisplayInformation().DashboardMenu.findIndex(function (e) {
                //    return (e.Attribute.startsWith("lm-rp-by-sub-cat-index"));
                //}) > -1) {
                //    readRpBySubCatIndexDBData();
                //}

                //if (displayService.getDisplayInformation().DashboardMenu.findIndex(function (e) {
                //    return (e.Attribute.startsWith("lm-np-by-sub-cat-index"));
                //}) > -1) {
                //    readNpBySubCatIndexDBData();
                //}
            });

            function dashboardFigureFormatter(val, dp) {
                var strVal = "0";

                if (val > 999999) {
                    strVal = (val / 1000000).toFixed(dp).toString() + " M";
                } else if (val > 999) {
                    strVal = (val / 1000).toFixed(dp).toString() + " K";
                } else {
                    strVal = val.toFixed(dp).toString();
                }

                return strVal;
            }

            function dashboardCalcPer(act, bgt) {
                var per = 0;

                if ((typeof bgt === "number" ? bgt : 0) != 0) {
                    per = ((typeof bgt === "number" ? bgt : 0).Nsub(typeof act === "number" ? act : 0)).Ndiv(typeof bgt === "number" ? bgt : 0).Nmul2(100);
                }

                return per;
            }

            function getMillDashboardFilterParam() {
                let firstLoad = typeof $rootScope.millDashboardFirstLoad === "undefined" ? true : $rootScope.millDashboardFirstLoad;
                let now = new Date();
                let today = $filter('date')(now, "dd/MM/yyyy");
                let objMillParam = { CompKey: authService.authentication.defaultCompKey, OUKey: [authService.authentication.defaultOUKey], FromDate: today, ToDate: today, firstLoad: firstLoad };
                var cookies = customLocalStorageService.getDashboardFilter();

                if (typeof cookies !== "undefined" && cookies != null) {
                    objMillParam.CompKey = cookies.compKey;
                    objMillParam.OUKey = cookies.ouKeys;
                    objMillParam.FromDate = $filter('date')(cookies.dateFrom, "dd/MM/yyyy");
                    objMillParam.ToDate = $filter('date')(cookies.dateTo, "dd/MM/yyyy");
                    objMillParam.firstLoad = firstLoad;
                    objMillParam.ShowBy = cookies.ShowBy;
                    objMillParam.FY = cookies.FY;
                    objMillParam.Period = cookies.Period;
                    objMillParam.DisplayType = cookies.TimeModeSelection;
                }

                return objMillParam;
            }

            function readMillDashboardData() {
                //promises = []

                var deferred = $q.defer();
                promises.push(deferred.promise);

                $q.all([$http({
                    url: $rootScope["dashboardWebApiurl"] + "MillDashboard/GetData",
                    method: "POST",
                    data: getMillDashboardFilterParam(),
                    headers: { 'Content-Type': 'application/json' },
                }), $http({
                    url: $rootScope["dashboardWebApiurl"] + "MillDashboard/GetData2",
                    method: "POST",
                    data: getMillDashboardFilterParam(),
                    headers: { 'Content-Type': 'application/json' },
                })]).then(function (returnValue) {
                    millDashboardData = returnValue[0].data;
                    millDashboardData = Object.assign(millDashboardData, returnValue[1].data);

                    deferred.resolve();
                });
            }

            function getMillDashboardData() {
                var deferred = $q.defer();

                $q.all(promises).then(function () {
                    deferred.resolve(millDashboardData ?? {
                        StrProdDate: new Date(),
                        FFBQty: {},
                        CPOQty: {},
                        PKQty: {},
                        FFBQtyYTD: {},
                        CPOQtyYTD: {},
                        PKQtyYTD: {},
                        FFBOERKER: [],
                        OilLosses: [],
                        CropRipeness: [],
                        FFBOERKERYTD: [],
                        OilLossesYTD: [],
                        CropRipenessYTD: [],
                        MillCostByOU: [],
                        MillOU: [],
                        CPOProducedByOU: [],
                        PKProducedByOU: [],
                        GradingInfoByOU: [],
                        GradingYTDInfoByOU: [],
                        MillOU: [],
                        CropRipenessALL: [],
                        USCrop: [],
                        PKLossesData: [],
                        LowCropQltyData: [],
                        CPOQualityData: {},
                        PKQualityData: {},
                    });
                });

                return deferred.promise;
            }

            function getGWIRateByInspector() {
                return getData(DATA.gwInspRatingByPercentage.url);
                /*
                var deferred = $q.defer();
                setInterval(function () { 
                    var values = [];
                    for (var i = 0; i < 50; i++) {
                        var prefixs = ["Mohammad", "Syamsuddin", "Kanaidah", "Hamid","Nornilah"];
                        var suffixs = ["Kerembong", "Sulaiman", "Amirahsan","Maisaroh","Kamaruddin"];
                        var prefix = prefixs[Math.ceil(Math.random() * (prefixs.length - 1))];
                        var suffix = suffixs[Math.ceil(Math.random() * (suffixs.length - 1))];
                        values.push({ inspector: `${prefix}-bin-${suffix}-${i}`, percentage: (100 - (i * 1.5 + Math.ceil(Math.random() * 5))), count: Math.ceil(Math.random() * 300) * 5 });
                    }
                    deferred.resolve({ data: values }) },
                500);
        
                return deferred.promise;
                */
            }

            function getGWIQualityGradingByInspector() {
                return getData(DATA.gwInspQualityGradedByInspector.url);
            }

            function getGWITopWorkers() {
                return getData(DATA.gwInspTop20Workers.url);
            }

            function getGWIBottomWorkers() {
                return getData(DATA.gwInspBottom20Workers.url);
            }

            function readMatureDBData() {
                //promises = []

                var deferred = $q.defer();
                promises.push(deferred.promise);

                var tempData = getMillDashboardFilterParam();

                var OUKeyList = "";
                for (var i = 0; i < tempData.OUKey.length; i++) {
                    OUKeyList = OUKeyList + tempData.OUKey[i] + ",";
                }
                OUKeyList = OUKeyList.substring(0, OUKeyList.length - 1);


                $q.all([$http({
                    url: $rootScope["dashboardWebApiurl"] + "CrDashboard/GetMatureCostConsolidate",
                    method: "GET",
                    params: { OUKey: -1, OUKeyList: OUKeyList, FY: tempData.FY, Period: 12, firstLoad: tempData.firstLoad, Method: "YTD" }

                })]).then(function (returnValue) {
                    matureDBData = returnValue[0].data;

                    deferred.resolve();
                });
            }

            function getMatureDBData() {
                var deferred = $q.defer();

                $q.all(promises).then(function () {
                    deferred.resolve(matureDBData);
                });

                return deferred.promise;
            }

            function readCapexDBData() {
                //promises = []

                var deferred = $q.defer();
                promises.push(deferred.promise);

                var tempData = getMillDashboardFilterParam();

                var OUKeyList = "";
                for (var i = 0; i < tempData.OUKey.length; i++) {
                    OUKeyList = OUKeyList + tempData.OUKey[i] + ",";
                }
                OUKeyList = OUKeyList.substring(0, OUKeyList.length - 1);


                $q.all([$http({
                    url: $rootScope["dashboardWebApiurl"] + "CrDashboard/GetCapexCostConsolidate",
                    method: "GET",
                    params: { OUKey: -1, OUKeyList: OUKeyList, FY: tempData.FY, Period: 12, realPeriod: tempData.Period, firstLoad: tempData.firstLoad, Method: "YTD" }

                })]).then(function (returnValue) {
                    capexDBData = returnValue[0].data;

                    deferred.resolve();
                });
            }

            function getCapexDBData() {
                var deferred = $q.defer();

                $q.all(promises).then(function () {
                    deferred.resolve(capexDBData);
                });

                return deferred.promise;
            }

            function readVehDBData() {
                //promises = []

                var deferred = $q.defer();
                promises.push(deferred.promise);

                var tempData = getMillDashboardFilterParam();

                var OUKeyList = "";
                for (var i = 0; i < tempData.OUKey.length; i++) {
                    OUKeyList = OUKeyList + tempData.OUKey[i] + ",";
                }
                OUKeyList = OUKeyList.substring(0, OUKeyList.length - 1);


                $q.all([$http({
                    url: $rootScope["dashboardWebApiurl"] + "CrDashboard/GetVehCostConsolidate",
                    method: "GET",
                    params: { OUKey: -1, OUKeyList: OUKeyList, FY: tempData.FY, Period: 12, firstLoad: tempData.firstLoad, Method: "YTD" }

                })]).then(function (returnValue) {
                    vehDBData = returnValue[0].data;

                    deferred.resolve();
                });
            }

            function getVehDBData() {
                var deferred = $q.defer();

                $q.all(promises).then(function () {
                    deferred.resolve(vehDBData);
                });

                return deferred.promise;
            }

            function readMaBySubCatIndexDBData() {
                //promises = []

                var deferred = $q.defer();
                promises.push(deferred.promise);

                var tempData = getMillDashboardFilterParam();

                var OUKeyList = "";
                for (var i = 0; i < tempData.OUKey.length; i++) {
                    OUKeyList = OUKeyList + tempData.OUKey[i] + ",";
                }
                OUKeyList = OUKeyList.substring(0, OUKeyList.length - 1);


                $q.all([$http({
                    url: $rootScope["dashboardWebApiurl"] + "CrDashboard/GetMaBySubCat",
                    method: "GET",
                    params: { OUKey: -1, OUKeyList: OUKeyList, FY: tempData.FY, Period: tempData.Period, firstLoad: tempData.firstLoad, Method: "YTD" }

                })]).then(function (returnValue) {
                    maBySubCatDBData = returnValue[0].data;

                    deferred.resolve();
                });
            }

            function getMaBySubCatData() {
                var deferred = $q.defer();

                $q.all(promises).then(function () {
                    deferred.resolve(maBySubCatDBData);
                });

                return deferred.promise;
            }

            function readRpBySubCatIndexDBData() {
                //promises = []

                var deferred = $q.defer();
                promises.push(deferred.promise);

                var tempData = getMillDashboardFilterParam();

                var OUKeyList = "";
                for (var i = 0; i < tempData.OUKey.length; i++) {
                    OUKeyList = OUKeyList + tempData.OUKey[i] + ",";
                }
                OUKeyList = OUKeyList.substring(0, OUKeyList.length - 1);


                $q.all([$http({
                    url: $rootScope["dashboardWebApiurl"] + "CrDashboard/GetImmatureExpbySubCat",
                    method: "GET",
                    params: { OUKey: -1, OUKeyList: OUKeyList, FY: tempData.FY, Period: 12, Type: "RP", firstLoad: tempData.firstLoad, Method: "YTD" }

                })]).then(function (returnValue) {
                    rpBySubCatDBData = returnValue[0].data;

                    deferred.resolve();
                });
            }

            function getRpBySubCatData() {
                var deferred = $q.defer();

                $q.all(promises).then(function () {
                    deferred.resolve(rpBySubCatDBData);
                });

                return deferred.promise;
            }

            function readNpBySubCatIndexDBData() {
                //promises = []

                var deferred = $q.defer();
                promises.push(deferred.promise);

                var tempData = getMillDashboardFilterParam();

                var OUKeyList = "";
                for (var i = 0; i < tempData.OUKey.length; i++) {
                    OUKeyList = OUKeyList + tempData.OUKey[i] + ",";
                }
                OUKeyList = OUKeyList.substring(0, OUKeyList.length - 1);


                $q.all([$http({
                    url: $rootScope["dashboardWebApiurl"] + "CrDashboard/GetImmatureExpbySubCat",
                    method: "GET",
                    params: { OUKey: -1, OUKeyList: OUKeyList, FY: tempData.FY, Period: 12, Type: "NP", firstLoad: tempData.firstLoad, Method: "YTD" }

                })]).then(function (returnValue) {
                    npBySubCatDBData = returnValue[0].data;

                    deferred.resolve();
                });
            }

            function getNpBySubCatData() {
                var deferred = $q.defer();

                $q.all(promises).then(function () {
                    deferred.resolve(npBySubCatDBData);
                });

                return deferred.promise;
            }

            function readRPDBData() {
                //promises = []

                var deferred = $q.defer();
                promises.push(deferred.promise);

                var tempData = getMillDashboardFilterParam();

                var OUKeyList = "";
                for (var i = 0; i < tempData.OUKey.length; i++) {
                    OUKeyList = OUKeyList + tempData.OUKey[i] + ",";
                }
                OUKeyList = OUKeyList.substring(0, OUKeyList.length - 1);

                $q.all([$http({
                    url: $rootScope["dashboardWebApiurl"] + "CrDashboard/GetImmatureCostConsolidateByType",
                    method: "GET",
                    params: {
                        OUKey: -1, OUKeyList: OUKeyList, FY: tempData.FY, Period: 12, Type: "RP", firstLoad: tempData.firstLoad, Method: "YTD"
                    }
                })]).then(function (returnValue) {
                    rpDBData = returnValue[0].data;

                    deferred.resolve();
                });
            }

            function getRPDBData() {
                var deferred = $q.defer();

                $q.all(promises).then(function () {
                    deferred.resolve(rpDBData);
                });

                return deferred.promise;
            }

            function readNPDBData() {
                //promises = []

                var deferred = $q.defer();
                promises.push(deferred.promise);

                var tempData = getMillDashboardFilterParam();

                var OUKeyList = "";
                for (var i = 0; i < tempData.OUKey.length; i++) {
                    OUKeyList = OUKeyList + tempData.OUKey[i] + ",";
                }
                OUKeyList = OUKeyList.substring(0, OUKeyList.length - 1);

                $q.all([$http({
                    url: $rootScope["dashboardWebApiurl"] + "CrDashboard/GetImmatureCostConsolidateByType",
                    method: "GET",
                    params: {
                        OUKey: -1, OUKeyList: OUKeyList, FY: tempData.FY, Period: 12, Type: "NP", firstLoad: tempData.firstLoad, Method: "YTD"
                    }
                })]).then(function (returnValue) {
                    npDBData = returnValue[0].data;

                    deferred.resolve();
                });
            }

            function getNPDBData() {
                var deferred = $q.defer();

                $q.all(promises).then(function () {
                    deferred.resolve(npDBData);
                });

                return deferred.promise;
            }

            function readGCDBData() {
                //promises = []

                var deferred = $q.defer();
                promises.push(deferred.promise);

                var tempData = getMillDashboardFilterParam();

                var OUKeyList = "";
                for (var i = 0; i < tempData.OUKey.length; i++) {
                    OUKeyList = OUKeyList + tempData.OUKey[i] + ",";
                }
                OUKeyList = OUKeyList.substring(0, OUKeyList.length - 1);

                $q.all([$http({
                    url: $rootScope["dashboardWebApiurl"] + "FinanceDashboard/GetGCConsolidate",
                    method: "GET",
                    params: {
                        OUKey: -1, OUKeyList: OUKeyList, FY: tempData.FY, Period: tempData.Period, firstLoad: tempData.firstLoad, Method: "YTD"
                    }
                })]).then(function (returnValue) {
                    gcDBData = returnValue[0].data;

                    deferred.resolve();
                });
            }

            function getGCDBData() {
                var deferred = $q.defer();

                $q.all(promises).then(function () {
                    deferred.resolve(gcDBData);
                });

                return deferred.promise;
            }

            function readNurSoldDBData() {
                //promises = []

                var deferred = $q.defer();
                promises.push(deferred.promise);

                var tempData = getMillDashboardFilterParam();

                var OUKeyList = "";
                for (var i = 0; i < tempData.OUKey.length; i++) {
                    OUKeyList = OUKeyList + tempData.OUKey[i] + ",";
                }
                OUKeyList = OUKeyList.substring(0, OUKeyList.length - 1);


                $q.all([$http({
                    url: $rootScope["dashboardWebApiurl"] + "NurDashboard/GetNurSoldConsolidate",
                    method: "GET",
                    params: { OUKeyList: OUKeyList, SoldDate: tempData.ToDate, CompKey: tempData.CompKey, FY: tempData.FY, Period: tempData.Period }

                })]).then(function (returnValue) {
                    nurSoldDBData = returnValue[0].data;

                    deferred.resolve();
                });
            }

            function getNurSoldDBData() {
                var deferred = $q.defer();

                $q.all(promises).then(function () {
                    deferred.resolve(nurSoldDBData);
                });

                return deferred.promise;
            }

            function readNurCullingDBData() {
                //promises = []

                var deferred = $q.defer();
                promises.push(deferred.promise);

                var tempData = getMillDashboardFilterParam();

                var OUKeyList = "";
                for (var i = 0; i < tempData.OUKey.length; i++) {
                    OUKeyList = OUKeyList + tempData.OUKey[i] + ",";
                }
                OUKeyList = OUKeyList.substring(0, OUKeyList.length - 1);


                $q.all([$http({
                    url: $rootScope["dashboardWebApiurl"] + "NurDashboard/GetNurCullingConsolidate",
                    method: "GET",
                    params: { OUKeyList: OUKeyList, CullingDate: tempData.ToDate, CompKey: tempData.CompKey, FY: tempData.FY, Period: tempData.Period }

                })]).then(function (returnValue) {
                    nurCullingDBData = returnValue[0].data;

                    deferred.resolve();
                });
            }

            function getNurCullingDBData() {
                var deferred = $q.defer();

                $q.all(promises).then(function () {
                    deferred.resolve(nurCullingDBData);
                });

                return deferred.promise;
            }

            function readNurAgingDBData() {
                //promises = []

                var deferred = $q.defer();
                promises.push(deferred.promise);

                var tempData = getMillDashboardFilterParam();

                var OUKeyList = "";
                for (var i = 0; i < tempData.OUKey.length; i++) {
                    OUKeyList = OUKeyList + tempData.OUKey[i] + ",";
                }
                OUKeyList = OUKeyList.substring(0, OUKeyList.length - 1);


                $q.all([$http({
                    url: $rootScope["dashboardWebApiurl"] + "NurDashboard/GetNurSeedlingAgeConsolidate",
                    method: "GET",
                    params: { OUKeyList: OUKeyList, SeedlingDate: tempData.ToDate, CompKey: tempData.CompKey, FY: tempData.FY, Period: tempData.Period }

                })]).then(function (returnValue) {
                    nurAgingDBData = returnValue[0].data;

                    deferred.resolve();
                });
            }

            function getNurAgingDBData() {
                var deferred = $q.defer();

                $q.all(promises).then(function () {
                    deferred.resolve(nurAgingDBData);
                });

                return deferred.promise;
            }

            return {
                getTrendLabelClass,
                getBacklogByDateRange,
                getBacklogEstimatedWeightByDateRange,
                getTotalBacklogBCC,
                getRawBacklogAndBacklogBCCByOUByDateRange,

                getMatureDBData,
                getCapexDBData,
                getVehDBData,
                //getMaBySubCatData,
                //getRpBySubCatData,
                //getNpBySubCatData,
                getRPDBData,
                getNPDBData,
                getGCDBData,

                getHarInspByDateRange,
                getHarInspRipenessPie,
                getHarInspRipenessCheckerPie,
                getGwInspStatusPie,
                getCropQualityRipenessByBlockByDateRange,
                getCropQualityUnsatisfactoryByBlockByDateRange,

                getWeightVarianceByOUByDateRange,
                getHarDespatchMillDateTrendsByDateRange,
                getHarDespatchMillMonthTrendsByDateRange,

                getLFWeightPercentByDateRange,
                getLeadTimeByDateRange,

                getTotalManDayByDateRange,
                getTotalManDayByOUByDateRange,

                getCheckerInspRipenessDateTrendsByDateRange,
                getCheckerInspRipenessMonthTrendsByDateRange,

                getEvacuationSingleFigureWidget,

                getHarEvaMonthTrendsByDateRange,
                getHarvestingEvacuationData: () => getData(URLConfig.HarvestingEvacuationData),
                getHarvestingCollectionData: () => getData(URLConfig.HarvestingCollectionData),
                getBacklogPlatformAging: () => getData(URLConfig.BacklogPlatformAging),
                getBacklogRampAging: () => getData(URLConfig.BacklogRampAging),
                getCropHarvesting: () => getData(URLConfig.Harvesting),
                getCropCollection: () => getData(URLConfig.Collection),
                getInFieldBacklog: () => getData(URLConfig.BacklogInField),
                getBinCollectionVSEvacuation: () => getData(URLConfig.CollectionEvacuationData),
                getCropBacklogBin: () => getData(URLConfig.BacklogBinAging),
                getDespatchedFromRamp: () => getData(URLConfig.DespatchFromRamp),
                getEvacuationTimeFromBin: () => getData(URLConfig.EvacuationTimeFromBin),
                getEvacuationFromBin: () => getData(URLConfig.EvacuationFromBin),
                getEvacuationTimeFromPlatform: () => getData(URLConfig.EvacuationTimeFromPlatform),
                getEvacuationFromPlatform: () => getData(URLConfig.EvacuationFromPlatform),
                getCropHarvestingInField: () => getData(URLConfig.CropHarvestingInField),
                getDespatchFromRamp: () => getData(URLConfig.DespatchFromRamp),
                getQCUserLastUpload: () => getData(URLConfig.QCUserLastUpload),

                getAttendancePie,
                getAttendanceByDateRange,

                getBunchesPie,
                getBunchesByDateRange,

                getLatestTopHarvestersByTotalRipe,
                getTopHarByEstimatedWeight,

                getFieldInspecTotalPending,

                getFirstDayOfTheMonth,
                getFirstDayOfTheYear,
                isValidDate: isValidDate,
                getTotalBCCnotInHarvesting,
                getHarEvaBunches,

                dashboardFigureFormatter,
                dashboardCalcPer,

                getMillDashboardFilterParam,
                getMillDashboardData,
                getGWInspCompletionDateTrendsByDateRange,
                getTop10InspInspector,
                getLatestInspectedInfo,
                getGWInspTimeline,
                getGWInspRatingPieChart,

                getGWIRateByInspector,
                getGWIQualityGradingByInspector,
                getGWITopWorkers,
                getGWIBottomWorkers,

                getNurSoldDBData,
                getNurCullingDBData,
                getNurAgingDBData
            }
        }]);


app.factory('dashboardUtil', ['DASHBOARDCONSTANT', 'customLocalStorageService', 'broadcastService', function (DASHBOARDCONSTANT, customLocalStorageService, broadcastService) {
    function getMsg() {
        return {
            NoData: "No data available for the selected filter",
            SomethingWrong: "Oops. Something goes wrong. Please try again later."
        }
    }
    function isLoading(dataRequestStatus) {
        return dataRequestStatus == DASHBOARDCONSTANT.DATA_STATUS.LOADING;
    }
    function isCompletedWithData(dataRequestStatus) {
        return dataRequestStatus == DASHBOARDCONSTANT.DATA_STATUS.COMPLETED_HAS_DATA;
    }
    function isCompletedWithNoData(dataRequestStatus) {
        return dataRequestStatus == DASHBOARDCONSTANT.DATA_STATUS.COMPLETED_NO_DATA;
    }
    function isError(dataRequestStatus) {
        return dataRequestStatus == DASHBOARDCONSTANT.DATA_STATUS.ERROR;
    }
    function getWidgetDateRange(widgetData) {
        var start = new Date(customLocalStorageService.getQCDashboardFilter().dateFrom);
        var end = new Date(customLocalStorageService.getQCDashboardFilter().dateTo);

        var globalMode = customLocalStorageService.getQCDashboardFilterTimeModeSelection();
        if (globalMode == DASHBOARDCONSTANT.MODE.BYLATEST.value) {
            if (widgetData.DateFrom && widgetData.DateTo)
                return `${kendo.toString(new Date(widgetData.DateFrom), 'dd/MM/yyyy')} - ${kendo.toString(new Date(widgetData.DateTo), 'dd/MM/yyyy')}`;
        }
        else if (globalMode == DASHBOARDCONSTANT.MODE.BYMTD.value) {
            return kendo.toString(start, 'MMM yyyy');
        }
        else if (globalMode == DASHBOARDCONSTANT.MODE.BYYTD.value) {
            return kendo.toString(start, 'yyyy');
        }
        else if (globalMode == DASHBOARDCONSTANT.MODE.BYDATERANGE.value) {
            return kendo.toString(start, 'dd/MM/yyyy') + " - " + kendo.toString(end, 'dd/MM/yyyy');
        }
        return "Invalid Date";
    }
    function getWidgetDateRangeModeSimple(result) {
        var pageLabel = {
            Date: broadcastService.getLabelDesc(214),
            Year: broadcastService.getLabelDesc(239),
            Month: broadcastService.getLabelDesc(150),
            Year_To_Date: broadcastService.getLabelDesc(2837),
            End_Date: broadcastService.getLabelDesc(136),
            Date_Range: broadcastService.getLabelDesc(4908)
        }
        var dateFrom = kendo.toString(new Date(result.DateFrom), 'dd/MM/yyyy');
        var dateTo = kendo.toString(new Date(result.DateTo), 'dd/MM/yyyy');
        var currentSelectedDateTo = new Date(customLocalStorageService.getQCDashboardFilter().dateTo);
        var Year = currentSelectedDateTo.getFullYear();
        var Month = broadcastService.convertMonthToString(currentSelectedDateTo.getMonth() + 1);

        var globalMode = customLocalStorageService.getQCDashboardFilterTimeModeSelection();
        if (globalMode == DASHBOARDCONSTANT.MODE.BYLATEST.value) {
            if (dateTo)
                return `${pageLabel.Date} : <b>${dateTo}</b>`;
        }
        else if (globalMode == DASHBOARDCONSTANT.MODE.BYMTD.value) {
            return `${pageLabel.Year} : <b>${Year}</b> &nbsp;&nbsp;|&nbsp;&nbsp; ${pageLabel.Month} : <b>${Month}</b>`;
        }
        else if (globalMode == DASHBOARDCONSTANT.MODE.BYYTD.value) {
            return `${pageLabel.Year} : <b>${Year}</b> &nbsp;&nbsp;|&nbsp;&nbsp; ${pageLabel.Month} : <b>${Month}</b> <b>(${pageLabel.Year_To_Date})</b>`;
        }
        else if (globalMode == DASHBOARDCONSTANT.MODE.BYDATERANGE.value) {
            return `${pageLabel.Date_Range} : <b>${dateFrom} - ${dateTo}`;
        }
        return 'Invalid Date';
    }

    function getTimelineCategoryAxisModeSingleChart(result) {
        var sampleSize = result.Result.length;
        var isDiffYear = (new Date(result.DateTo)).getFullYear() != (new Date(result.DateFrom)).getFullYear();

        var globalMode = customLocalStorageService.getQCDashboardFilterTimeModeSelection();

        if (globalMode == DASHBOARDCONSTANT.MODE.BYLATEST.value) {
            if (isDiffYear)
                return {
                    type: "date",
                    baseUnit: "days",
                    labels: {
                        step: 1,
                        dateFormats: {
                            days: "dd/MMM/yy"
                        }
                    },
                    majorGridLines: {
                        visible: false
                    }
                };
            return {
                type: "date",
                baseUnit: "days",
                labels: {
                    step: 1,
                    dateFormats: {
                        days: "dd/MMM"
                    }
                },
                majorGridLines: {
                    visible: false
                }
            };
        }
        if (globalMode == DASHBOARDCONSTANT.MODE.BYMTD.value) {
            return {
                type: "date",
                baseUnit: "days",
                labels: {
                    step: (sampleSize).Ndiv(7).Nceil(),
                    dateFormats: {
                        days: "dd/MMM"
                    }
                },
                majorGridLines: {
                    visible: false
                }
            };
        }
        if (globalMode == DASHBOARDCONSTANT.MODE.BYYTD.value) {
            if (sampleSize < 120) {
                return {
                    type: "date",
                    baseUnit: "days",
                    labels: {
                        step: (sampleSize).Ndiv(7).Nceil(),
                        dateFormats: {
                            days: "dd/MMM"
                        }
                    },
                    majorGridLines: {
                        visible: false
                    }
                };
            }
            return {
                type: "date",
                baseUnit: "days",
                labels: {
                    step: (sampleSize).Ndiv(4).Nceil(),
                    dateFormats: {
                        days: "MMM"
                    }
                },
                majorGridLines: {
                    visible: false
                }
            };
        }
        if (globalMode == DASHBOARDCONSTANT.MODE.BYDATERANGE.value) {
            if (isDiffYear && sampleSize > 120) {
                return {
                    type: "date",
                    baseUnit: "days",
                    labels: {
                        step: (sampleSize).Ndiv(4).Nceil(),
                        dateFormats: {
                            days: "MMM/yyyy"
                        }
                    },
                    majorGridLines: {
                        visible: false
                    }
                };
            }
            if (isDiffYear) {
                return {
                    type: "date",
                    baseUnit: "days",
                    labels: {
                        step: (sampleSize).Ndiv(7).Nceil(),
                        dateFormats: {
                            days: "dd/MM/yy"
                        }
                    },
                    majorGridLines: {
                        visible: false
                    }
                };
            }
            return {
                type: "date",
                baseUnit: "days",
                labels: {
                    step: (sampleSize).Ndiv(7).Nceil(),
                    dateFormats: {
                        days: "dd/MMM"
                    }
                },
                majorGridLines: {
                    visible: false
                }
            };
        }
        return {};
    }
    function getTimelineCategoryAxisModeMultiCharts(result) {
        var sampleSize = result.Result.length;
        var globalMode = customLocalStorageService.getQCDashboardFilterTimeModeSelection();
        if (globalMode == DASHBOARDCONSTANT.MODE.BYLATEST.value) {
            return {
                type: "date",
                baseUnit: "days",
                majorGridLines: {
                    visible: false
                },
                labels: {
                    step: 1,
                    template: function (data) {
                        return kendo.toString(data.value, "dd/MMM")
                    }
                }
            };
        }
        if (globalMode == DASHBOARDCONSTANT.MODE.BYMTD.value) {
            return {
                type: "date",
                baseUnit: "days",
                majorGridLines: {
                    visible: false
                },
                labels: {
                    step: (sampleSize).Ndiv(7).Nceil(),
                    template: function (data) {
                        return kendo.toString(data.value, "dd/MMM")
                    }
                }
            };
        }
        if (globalMode == DASHBOARDCONSTANT.MODE.BYYTD.value) {
            return {
                type: "date",
                baseUnit: "months",
                majorGridLines: {
                    visible: false
                },
                labels: {
                    template: function (data) {
                        return kendo.toString(data.value, 'dd/MMM')
                    }
                }
            };
        }
        if (globalMode == DASHBOARDCONSTANT.MODE.BYDATERANGE.value) {
            var firstElem = new Date(result.DateFrom);
            var lastElem = new Date(result.DateTo);
            if (firstElem.getFullYear() === lastElem.getFullYear()) {
                if (firstElem.getMonth() === lastElem.getMonth()) {
                    var diffMilliSecs = lastElem - firstElem;
                    var diffDays = (diffMilliSecs).Ndiv(1000 * 60 * 60 * 24).Nfloor() + 1;

                    if (diffDays <= 7) {
                        return {
                            type: "date",
                            baseUnit: "days",
                            majorGridLines: {
                                visible: false
                            },
                            labels: {
                                step: 1,
                                template: function (data) {
                                    return kendo.toString(data.value, "dd/MMM")
                                }
                            }
                        };
                    }
                    else {
                        return {
                            type: "date",
                            baseUnit: "days",
                            majorGridLines: {
                                visible: false
                            },
                            labels: {
                                step: (sampleSize).Ndiv(7).Nceil(),
                                template: function (data) {
                                    return kendo.toString(data.value, "dd/MMM")
                                }
                            }
                        };
                    }
                }
                else {
                    return {
                        type: "date",
                        baseUnit: "months",
                        majorGridLines: {
                            visible: false
                        },
                        labels: {
                            template: function (data) {
                                return kendo.toString(data.value, 'dd/MMM')
                            }
                        }
                    };
                }
            }
            return {
                type: "date",
                baseUnit: "years",
                majorGridLines: {
                    visible: false
                }
            };
        }
        return {};
    }
    function getResetChartData() {
        return {
            requestStatus: DASHBOARDCONSTANT.DATA_STATUS.LOADING,
            option: null,
            dataSource: null
        };
    }
    return {
        isLoading,
        isCompletedWithData,
        isCompletedWithNoData,
        isError,
        getWidgetDateRange,
        getWidgetDateRangeModeSimple,
        getResetChartData,
        getMsg,
        getTimelineCategoryAxisModeSingleChart,
        getTimelineCategoryAxisModeMultiCharts
    }
}])